import LandingSection from '@/components/landing/LandingSection'
import LinkIcon from '@/components/LinkIcon'
import Students from 'public/img/students.svg'
import Creators from 'public/img/creators.svg'
import analytics from '@/utils/analytics'
import { useRouter } from 'next/router'
import { useState } from 'react'
import toast from 'react-hot-toast'
import Link from 'next/link'
import { useShowLandingDemoModal } from '@/components/Modal'
import dynamic from 'next/dynamic'
import { FileText, Search, Camera, Download } from 'react-feather'
import {
  getYouTubeID,
  getYouTubeVideoData,
  isYoutubeUrl,
  getRandomID,
  demoVideo,
} from '@/utils/index'
import classNames from 'classnames'

const ImageWithFallback = dynamic(
  () => import('@/components/ImageWithFallback'),
  { ssr: false }
)

export default function Home() {
  return (
    <LandingSection title="Take notes on videos">
      <Hero />
      <Features />
      <Cases />
      <Testimonials />
      <BottomCta />
    </LandingSection>
  )
}

function Hero() {
  return (
    <div className="bg-white pb-8 sm:pb-12 lg:pb-12">
      <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-28">
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
          <div>
            <div>
              <div className="sm:max-w-xl mb-14">
                <h1 className="block mb-3 text-5xl tracking-tight font-extrabold md:text-6xl xl:text-7xl">
                  <span className="text-gradient bg-gradient-to-r from-green-400 to-primary">
                    Optimize
                  </span>{' '}
                  <br className="hidden lg:block" />
                  your{' '}
                  <span className="text-gradient bg-gradient-to-r from-green-400 to-primary">
                    learning
                  </span>
                  <br className="hidden lg:block" /> on YouTube.
                </h1>
                <h2 className="text-gray-500 my-5 text-2xl">
                  Take notes on videos and capture the key takeaways.
                </h2>
              </div>
              <TryDemo />
            </div>
          </div>
        </div>

        <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
          <div className="py-12 sm:relative sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <div className="hidden sm:block">
              <div className="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full" />
              <svg
                className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
                width="404"
                height="392"
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="392"
                  fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                />
              </svg>
            </div>
            <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12 h-1/2">
              <div className="w-full h-1/2 rounded-md bg-gray-200 shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none 2xl:bg-transparent 2xl:shadow-none 2xl:ring-0 2xl:rounded-none">
                <PlayButton />
                <ImageWithFallback
                  className="w-full h-1/2 rounded-md lg:h-full lg:w-auto lg:max-w-none 2xl:bg-gray-200 2xl:shadow-xl 2xl:ring-1 2xl:ring-black 2xl:ring-opacity-5"
                  src="/img/hero.webp"
                  alt="hero"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function TryDemo() {
  const router = useRouter()
  const [isLoading, setIsLoading] = useState(false)
  const [url, setUrl] = useState('')

  function setDemo() {
    const demoUrl = 'https://youtu.be/Bw9P_ZXWDJU'
    setUrl(demoUrl)
    onSubmit(null, true)
  }

  async function onSubmit(e, isDemo = false) {
    if (e) {
      e.preventDefault()
    }

    analytics.track('(Demo) Video added')

    if (isDemo) {
      setIsLoading(true)
      localStorage.setItem('demoVideo', JSON.stringify(demoVideo))

      analytics.track('(Demo) Example video added')
      toast.success('Video added')

      router.push('/demo')
      return
    }

    if (!url) {
      toast.error('Please, enter a URL')
      return
    }

    if (url.includes('playlist')) {
      toast.error("We don't support playlists yet")
      return
    }

    if (!isYoutubeUrl(url)) {
      toast.error('Must be a valid YouTube URL')
      return
    }

    setIsLoading(true)

    try {
      const ytID = getYouTubeID(url)
      const videoInfo = await getYouTubeVideoData({ id: ytID })

      if (!videoInfo) {
        setIsLoading(false)
        analytics.track('Tried creating an invalid demo URL', { url })
        toast.error('Invalid URL')
        return
      }

      analytics.track('(Demo) Custom video added')

      const demoVideo = {
        _id: getRandomID(),
        url: `https://youtube.com/watch?v=${videoInfo.id}`,
        title: videoInfo.title,
        duration: videoInfo.duration,
        thumbnails: videoInfo.thumbnails,
        description: videoInfo.description,
        channelId: videoInfo.channelId,
        channelTitle: videoInfo.channelTitle,
        categoryId: videoInfo.categoryId,
        originalTags: videoInfo.tags,
        platform: 'youtube',
      }

      localStorage.setItem('demoVideo', JSON.stringify(demoVideo))

      analytics.track('Demo video Added')
      toast.success('Video added')
      setIsLoading(false)

      router.push('/demo')
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      analytics.track('Error adding demo video', { video: url })
      toast.error('Something went wrong')
    }
  }

  return (
    <>
      <form className="space-y-6" onSubmit={onSubmit}>
        <div className="mt-1 flex flex-col rounded-md shadow-sm md:flex-row">
          <div className="relative flex items-stretch flex-grow focus-within:z-10">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <LinkIcon className="h-5 w-5 text-gray-400" />
            </div>
            <label className="absolute -top-96 -left-96" htmlFor="url">
              Url
            </label>
            <input
              type="text"
              name="url"
              id="url"
              onChange={({ target }) => setUrl(target.value)}
              className="bg-gray-50 focus:ring-primary focus:border-primary block w-full rounded-none rounded-l-md rounded-r-md pl-10 py-3 items-center text-lg border-gray-300 md:rounded-r-none"
              placeholder="Paste a YouTube link"
            />
          </div>
          <button
            disabled={isLoading}
            className="center mt-3 -ml-px bg-primary text-white relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-lg font-medium rounded-l-md rounded-r-md clickable-opacity focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary md:rounded-l-none md:block md:mt-0"
          >
            <span>Start annotating!</span>
          </button>
        </div>
      </form>

      <div
        className="text-center mx-auto mt-5 text-lg underline text-primary cursor-pointer clickable-opacity"
        onClick={setDemo}
      >
        Use example video
      </div>
    </>
  )
}

function PlayButton() {
  const showModal = useShowLandingDemoModal()

  return (
    <>
      <div
        onClick={showModal}
        className="absolute top-1/2 transform -translate-x-1/2 -translate-y-1/2 play-substract sm:play-add md:play-add lg:play-add xl:play-add 2xl:play-substract"
      >
        <PlayIcon />
      </div>
    </>
  )
}

function PlayIcon() {
  return (
    <div className="clickable shadow-md rounded-full transform hover:scale-125">
      <svg
        className="h-20 w-20 text-primary"
        fill="currentColor"
        viewBox="0 0 84 84"
      >
        <circle opacity="1" cx="42" cy="42" r="42" fill="white" />
        <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
      </svg>
    </div>
  )
}

function Features() {
  const data = [
    {
      mediaOnLeft: true,
      title: 'Transcript',
      description:
        'No need to write what you just heard. We get the transcript of the video so you can easily use it.',
      icon: <FileText className="h-6 w-6 text-white" />,
      media: {
        type: 'video',
        fileName: 'transcript',
      },
    },
    {
      mediaOnLeft: false,
      title: 'Text from video',
      description:
        'No need to take notes from your video slides. Just click a button and we will take care of the rest.',
      icon: <Camera className="h-6 w-6 text-white" />,
      media: {
        type: 'video',
        fileName: 'ocr',
      },
    },
    {
      mediaOnLeft: true,
      title: 'Search',
      description:
        'Search across all your notes and instantly find what you are looking for.',
      icon: <Search className="h-6 w-6 text-white" />,
      media: {
        type: 'video',
        fileName: 'search',
      },
    },
    {
      mediaOnLeft: false,
      title: 'Export',
      description:
        'Export your notes to PDF, Evernote and Notion. Or sync them with Readwise. Read them wherever and whenever you want.',
      icon: <Download className="h-6 w-6 text-white" />,
      media: {
        type: 'image',
        fileName: 'export',
      },
    },
  ]

  return (
    <div className="bg-gray-100 border-t-2 border-b-2 mt-20 pt-10 h-auto lg:pt-28 ">
      {data.map((item) => (
        <Feature key={item.title} {...item} />
      ))}
    </div>
  )
}

function Feature({ mediaOnLeft = true, media, title, description, icon }) {
  const mediaClassName = classNames(
    'width-auto max-w-xs rounded-lg m-5 shadow-xl md:max-w-md lg:max-w-md',
    { 'ml-0': mediaOnLeft, 'mr-0': !mediaOnLeft }
  )

  const MediaComponent = <Media {...media} className={mediaClassName} />

  const className = `rounded-lg relative ${
    mediaOnLeft ? 'flex-col' : 'flex-col-reverse'
  } center md:flex-row`

  return (
    <div className="mb-16 mx-auto max-w-7xl px-4 sm:px-6 lg:mb-40">
      <div className={className}>
        {mediaOnLeft && MediaComponent}
        <div className="text-black flex flex-col p-10 justify-center">
          <span className="h-12 w-12 mb-3 rounded-md flex items-center justify-center bg-primary">
            {icon}
          </span>
          <h2 className="text-4xl font-bold">{title}</h2>
          <span className="text-xl text-gray-800 mt-5">{description}</span>
        </div>
        {!mediaOnLeft && MediaComponent}
      </div>
    </div>
  )
}

function Media({ type, fileName, className = '' }) {
  if (type === 'video') {
    return (
      <video
        className={className}
        loop
        autoPlay
        muted
        playsInline
        preload="1"
        poster={`/img/${fileName}.webp`}
      >
        <source type="video/mp4" src={`/videos/landing/${fileName}.mp4`} />
        <source type="video/webm" src={`/videos/landing/${fileName}.webm`} />
      </video>
    )
  } else {
    return (
      <ImageWithFallback
        src={`/img/${fileName}.webp`}
        alt={fileName}
        className={className}
      />
    )
  }
}

function Cases() {
  return (
    <div className="bg-white mt-20 h-auto">
      <div className="mb-16 mx-auto max-w-7xl px-4 sm:px-6">
        <div className="bg-gray-100 rounded-lg relative center flex flex-col lg:flex-row">
          <Students className="width-auto max-w-xs w-full" />
          <div className="text-black flex flex-col p-10 justify-center">
            <h2 className="text-5xl font-bold">Learning</h2>
            <span className="text-xl text-gray-800 mt-5">
              Actively taking notes can help you focus and better understand
              main concepts. Good note-taking will improve your active
              listening, comprehension of material, and retention. It will help
              you better remember what you hear and see.
            </span>
          </div>
        </div>
      </div>

      <div className="mb-16 mx-auto max-w-7xl px-4 sm:px-6">
        <div className="bg-gray-100 rounded-lg relative center flex flex-col-reverse lg:flex-row">
          <div className="text-black flex flex-col p-10 justify-center">
            <h2 className="text-5xl font-bold">Teaching</h2>
            <span className="text-xl text-gray-800 mt-5">
              Make the life easier for your audience. Link to any book, paper or
              docs you mention. Do you have important text in your slides? Use
              Annotate.tv for easy copy/paste. Want to add something? No need to
              re-shoot your video, just edit your notes.
            </span>
          </div>
          <Creators className="width-auto w-full max-w-xs" />
        </div>
      </div>
    </div>
  )
}

function Testimonials() {
  const data = [
    {
      author: 'Mark Lavercombe',
      description: 'Physician / Medical Educator',
      text:
        '"Annotate.tv has quickly become a vital component of my personal knowledge management workflow, and I will be recommending it to my readers and students."',
      url: 'https://productivephysician.com',
      avatar: 'mark',
      className: 'border-b lg:border-r lg:border-b-0 border-gray-300',
    },
    {
      author: 'Mike Lang',
      description: 'K-5 Teacher',
      text:
        '"Annotate.tv has become invaluable to my instruction and I plan on using it for the rest of my career. If you are a teacher who is using YouTube in your classroom, Annotate.tv is an absolute game changer."',
      url: 'https://www.theintelligenthoodlums.com',
      avatar: 'mike',
      className: 'border-b lg:border-r lg:border-b-0 border-gray-300',
    },
    {
      author: 'Adam Moody',
      description: 'Productivity Academy',
      text:
        '"Annotate.tv has opened up an entirely new way to easily get information and actionable notes from videos. It\'s addition to my workflow is helping immensely."',
      url: 'https://productivity.academy',
      avatar: 'adam',
    },
  ]

  return (
    <div className="mx-auto px-4 sm:px-6 md:max-w-7xl">
      <div className="grid grid-rows-3 grid-cols-none gap-4 bg-gray-200 rounded-lg lg:grid-cols-3 lg:grid-rows-none">
        {data.map((item) => (
          <Testimonial key={item.author} {...item} />
        ))}
      </div>
    </div>
  )
}

function Testimonial({
  author,
  description,
  text,
  url,
  avatar,
  className = '',
}) {
  return (
    <div className={`flex flex-col p-5 lg:p-10 ${className}`}>
      <div className="mb-4 text-gray-800 text-lg">{text}</div>
      <div className="flex items-center mt-auto">
        <ImageWithFallback
          width="50px"
          height="50px"
          className="rounded-md"
          alt={author}
          src={`/img/testimonials/${avatar}.webp`}
        />
        <div className="flex flex-col ml-5">
          <a
            className="font-bold"
            target="_blank"
            rel="noopener noreferrer"
            href={url}
          >
            {author}
          </a>
          <span>{description}</span>
        </div>
      </div>
    </div>
  )
}

function BottomCta() {
  return (
    <div className="mx-auto px-4 sm:px-6 md:max-w-7xl my-16 w-full">
      <div
        className="flex flex-col bg-primary bg-footer-cta-pattern rounded-lg p-16 bg-cover bg-center bg-no-repeat items-center justify-center lg:flex-row"
        onClick={() => analytics.track('Footer get started clicked')}
      >
        <h3 className="text-white text-3xl font-bold lg:mr-16 lg:text-4xl">
          Optimize your learning now!
        </h3>
        <Link href="/signup">
          <a className="mt-10 clickable-opacity bg-white text-primary p-4 text-xl rounded-lg lg:mt-0">
            Get started for free
          </a>
        </Link>
      </div>
    </div>
  )
}
